// DashboardClients.js

import React, { useState } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import {
  Paper,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  CircularProgress,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { Chart } from 'react-chartjs-2';
import 'chart.js/auto';

// Create an axios instance with the base URL of your backend server
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`, // Ensure this points to your backend server
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`, // Include auth headers if needed
  },
});

const DashboardClients = () => {
  const [loading, setLoading] = useState(false);
  
  // Initialize metrics with default values to prevent undefined errors
  const [metrics, setMetrics] = useState({
    totalClients: 0,
    totalAdultClients: 0,
    totalYouthClients: 0,
    avgAgeAdult: 0,
    avgAgeYouth: 0,
    occupancyRates: {
      adult: { current: 0, max: 65, rate: 0 },
      youth: { current: 0, max: 10, rate: 0 },
      overall: 0,
    },
  });

  const [adultStatusCounts, setAdultStatusCounts] = useState({});
  const [youthStatusCounts, setYouthStatusCounts] = useState({});
  const [progressDataAdult, setProgressDataAdult] = useState([]);
  const [progressDataYouth, setProgressDataYouth] = useState([]);
  const [adultAges, setAdultAges] = useState([]);
  const [youthAges, setYouthAges] = useState([]);
  const [adultTreatmentDurations, setAdultTreatmentDurations] = useState([]);
  const [youthTreatmentDurations, setYouthTreatmentDurations] = useState([]);

  const [adultAgeGroups, setAdultAgeGroups] = useState([]);
  const [youthAgeGroups, setYouthAgeGroups] = useState([]);
  const [adultAgeStats, setAdultAgeStats] = useState({});
  const [youthAgeStats, setYouthAgeStats] = useState({});

  const handleGetData = async () => {
    setLoading(true);

    try {
      const response = await api.get('/dashboard-clients');
      const data = response.data;

      // Update metrics
      setMetrics({
        totalClients: data.totalClients || 0,
        totalAdultClients: data.totalAdultClients || 0,
        totalYouthClients: data.totalYouthClients || 0,
        avgAgeAdult: data.avgAgeAdult || 0,
        avgAgeYouth: data.avgAgeYouth || 0,
        occupancyRates: data.occupancyRates || {
          adult: { current: 0, max: 65, rate: 0 },
          youth: { current: 0, max: 10, rate: 0 },
          overall: 0,
        },
      });

      setAdultStatusCounts(data.adultStatusCounts || {});
      setYouthStatusCounts(data.youthStatusCounts || {});
      setProgressDataAdult(data.progressDataAdult || []);
      setProgressDataYouth(data.progressDataYouth || []);
      setAdultAges(data.adultAges || []);
      setYouthAges(data.youthAges || []);
      setAdultTreatmentDurations(data.adultTreatmentDurations || []);
      setYouthTreatmentDurations(data.youthTreatmentDurations || []);

      setAdultAgeGroups(data.adultAgeGroups || []);
      setYouthAgeGroups(data.youthAgeGroups || []);
      setAdultAgeStats(data.adultAgeStats || {});
      setYouthAgeStats(data.youthAgeStats || {});
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      alert('Failed to fetch dashboard data.');
    } finally {
      setLoading(false);
    }
  };

  // Helper function to transform status counts object into arrays
  const transformStatusCounts = (statusCounts) => {
    return {
      labels: Object.keys(statusCounts),
      data: Object.values(statusCounts),
    };
  };

  // Helper function to render Pie Charts
  const renderPieChart = (dataObj, title, backgroundColors) => (
    <Paper elevation={3} sx={{ p: 2, height: '300px', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Chart
          type="pie"
          data={{
            labels: dataObj.labels,
            datasets: [
              {
                data: dataObj.data,
                backgroundColor: backgroundColors || [
                  '#FF6384',
                  '#36A2EB',
                  '#FFCE56',
                  '#4BC0C0',
                  '#9966FF',
                  '#FF9F40',
                ],
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
          }}
        />
      </Box>
    </Paper>
  );

  // Helper function to render Bar Charts
  const renderBarChart = (data, title) => {
    // Sort data by week
    const sortedData = data.sort((a, b) => a.Week - b.Week);

    // Prepare datasets
    const earlyStage = sortedData.map(item => item.Week <= 4 ? item.Clients : 0);
    const midStage = sortedData.map(item => item.Week > 4 && item.Week <= 12 ? item.Clients : 0);
    const lateStage = sortedData.map(item => item.Week > 12 ? item.Clients : 0);

    return (
      <Paper elevation={3} sx={{ p: 2, height: '300px', display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Box sx={{ flexGrow: 1, position: 'relative' }}>
          <Chart
            type="bar"
            data={{
              labels: sortedData.map(item => `Week ${item.Week}`),
              datasets: [
                {
                  label: 'Early Stage (0-4 weeks)',
                  data: earlyStage,
                  backgroundColor: '#FF6384', // Red
                },
                {
                  label: 'Mid Stage (5-12 weeks)',
                  data: midStage,
                  backgroundColor: '#36A2EB', // Blue-green
                },
                {
                  label: 'Late Stage (13+ weeks)',
                  data: lateStage,
                  backgroundColor: '#4BC0C0', // Green
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                  beginAtZero: true,
                },
              },
            }}
          />
        </Box>
      </Paper>
    );
  };

  // Helper function to render Line Charts
  const renderLineChart = (labels, data, title, labelName) => (
    <Paper elevation={3} sx={{ p: 2, height: '300px', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Chart
          type="line"
          data={{
            labels: labels,
            datasets: [
              {
                label: labelName || 'Count',
                data: data,
                backgroundColor: '#36A2EB',
                borderColor: '#36A2EB',
                fill: false,
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          }}
        />
      </Box>
    </Paper>
  );

  // Helper function to render Histogram
  const renderHistogram = (labels, data, title, labelName) => (
    <Paper elevation={3} sx={{ p: 2, height: '300px', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Chart
          type="bar"
          data={{
            labels: labels,
            datasets: [
              {
                label: labelName || 'Count',
                data: data,
                backgroundColor: '#36A2EB',
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          }}
        />
      </Box>
    </Paper>
  );

  // Helper function to render Bar Charts for Age Groups
  const renderAgeGroupBarChart = (data, title) => (
    <Paper elevation={3} sx={{ p: 2, height: '300px', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Chart
          type="bar"
          data={{
            labels: data.map(item => item.ageGroup),
            datasets: [
              {
                label: 'Count',
                data: data.map(item => item.count),
                backgroundColor: '#36A2EB',
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          }}
        />
      </Box>
    </Paper>
  );

  // Helper function to render Age Statistics
  const renderAgeStats = (stats, title) => (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography>Minimum Age: {stats.min?.toFixed(1)}</Typography>
      <Typography>Maximum Age: {stats.max?.toFixed(1)}</Typography>
      <Typography>Median Age: {stats.median?.toFixed(1)}</Typography>
      <Typography>1st Quartile (Q1): {stats.q1?.toFixed(1)}</Typography>
      <Typography>3rd Quartile (Q3): {stats.q3?.toFixed(1)}</Typography>
    </Paper>
  );

  return (
    <>
      <Header />
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Client Dashboard
        </Typography>
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGetData}
            disabled={loading}
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : 'Get Current Client Data'}
          </Button>
        </Paper>

        {/* Metrics Overview */}
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Current Client Overview
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography variant="h6">
                Total Clients: {metrics.totalClients}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="h6">
                Adult Clients: {metrics.totalAdultClients}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="h6">
                Youth Clients: {metrics.totalYouthClients}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="h6">
                Overall Occupancy: {metrics.occupancyRates.overall}%
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        {/* Visualizations */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            {Object.keys(adultStatusCounts).length > 0 &&
              renderPieChart(
                transformStatusCounts(adultStatusCounts),
                'Adult Client Status Distribution'
              )}
          </Grid>
          <Grid item xs={12} md={4}>
            {Object.keys(youthStatusCounts).length > 0 &&
              renderPieChart(
                transformStatusCounts(youthStatusCounts),
                'Youth Client Status Distribution'
              )}
          </Grid>
          <Grid item xs={12} md={4}>
            {progressDataAdult.length > 0 &&
              renderBarChart(progressDataAdult, 'Adult Treatment Progress')}
          </Grid>
          <Grid item xs={12} md={4}>
            {progressDataYouth.length > 0 &&
              renderBarChart(progressDataYouth, 'Youth Treatment Progress')}
          </Grid>
          <Grid item xs={12} md={4}>
            {adultAges.length > 0 &&
              renderLineChart(
                adultAges.map((_, index) => `Client ${index + 1}`),
                adultAges,
                'Adult Age Distribution',
                'Age'
              )}
          </Grid>
          <Grid item xs={12} md={4}>
            {youthAges.length > 0 &&
              renderLineChart(
                youthAges.map((_, index) => `Client ${index + 1}`),
                youthAges,
                'Youth Age Distribution',
                'Age'
              )}
          </Grid>
          <Grid item xs={12} md={4}>
            {adultTreatmentDurations.length > 0 &&
              renderHistogram(
                adultTreatmentDurations.map((_, index) => `Client ${index + 1}`),
                adultTreatmentDurations,
                'Adult Treatment Duration',
                'Weeks'
              )}
          </Grid>
          <Grid item xs={12} md={4}>
            {youthTreatmentDurations.length > 0 &&
              renderHistogram(
                youthTreatmentDurations.map((_, index) => `Client ${index + 1}`),
                youthTreatmentDurations,
                'Youth Treatment Duration',
                'Weeks'
              )}
          </Grid>
          <Grid item xs={12} md={4}>
            {adultAgeGroups.length > 0 &&
              renderAgeGroupBarChart(adultAgeGroups, 'Adult Age Distribution')}
          </Grid>
          <Grid item xs={12} md={4}>
            {youthAgeGroups.length > 0 &&
              renderAgeGroupBarChart(youthAgeGroups, 'Youth Age Distribution')}
          </Grid>
          <Grid item xs={12} md={4}>
            {Object.keys(adultAgeStats).length > 0 &&
              renderAgeStats(adultAgeStats, 'Adult Age Statistics')}
          </Grid>
          <Grid item xs={12} md={4}>
            {Object.keys(youthAgeStats).length > 0 &&
              renderAgeStats(youthAgeStats, 'Youth Age Statistics')}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DashboardClients;