import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import lastdoor from '../resources/lastdoor.png';
import { AppBar, Toolbar, Typography, Button, Box, Menu, MenuItem, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {
  const navigate = useNavigate();
  const { auth, logout } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
    handleClose();
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    logout();
    navigate('/');
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ 
            mr: 2,
            '& .MuiSvgIcon-root': {
              fontSize: 32, // Increase icon size
              fontWeight: 'bold', // Make icon bolder
            },
          }}
          onClick={handleMenu}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', display: 'flex', alignItems: 'center' }}>
          <img 
            src={lastdoor} 
            alt="Logo" 
            style={{ 
              maxWidth: '25%', 
              maxHeight: 50, 
              padding: '15px 0', 
              marginRight: 16,
              filter: 'invert(1)'
            }} 
          />
          <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
            MultiTool
          </Typography>
        </Box>

        {auth.token && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
              Welcome, <strong>{auth.user.username}</strong>
            </Typography>
            <Button color="inherit" onClick={handleLogout}>Logout</Button>
          </Box>
        )}

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleNavigation('/landing')}>Home</MenuItem>
          <MenuItem onClick={() => handleNavigation('/dashboard-combined')}>Dashboard Combined</MenuItem>
          <MenuItem onClick={() => handleNavigation('/clinical-generator')}>Clinical Generator</MenuItem>
          <MenuItem onClick={() => handleNavigation('/dashboard-calls')}>Dashboard Calls</MenuItem>
          <MenuItem onClick={() => handleNavigation('/dashboard-clients')}>Dashboard Clients</MenuItem>
          <MenuItem onClick={() => handleNavigation('/dashboard-waitlist')}>Dashboard Waitlist</MenuItem>
          <MenuItem onClick={() => handleNavigation('/mrp-interpretive-summary')}>MRP Interpretive Summary</MenuItem>
          <MenuItem onClick={() => handleNavigation('/ask-the-crm')}>Ask the CRM</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;