import React, { useState } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import { Paper, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import lastdoorLogo from '../resources/lastdoor.png';

// Create an axios instance with the base URL of your backend server
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`, // Ensure this points to your backend server
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`, // Include auth headers if needed
  },
});

// Add an interceptor to include the token in every request
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token'); // Adjust this if you store the token differently
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

const AskTheCRM = () => {
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setResponse('');

    try {
      // Use the api instance to make the request
      const result = await api.post('/ask-crm', { query });
      setResponse(result.data);
    } catch (err) {
      setError('An error occurred while processing your request.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const renderResponse = (response) => {
    if (!response || !response.results || response.results.length === 0) {
      return <Typography>No results found.</Typography>;
    }

    const columns = Object.keys(response.results[0]);

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="response table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {response.results.map((row, index) => (
              <TableRow key={index}>
                {columns.map((column) => (
                  <TableCell key={column}>{row[column]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <Header />
      <div className="landing-container">
        <Paper elevation={3} sx={{ p: 3, m: 2 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h4" gutterBottom>
              Ask The CRM
            </Typography>
            <Typography variant="body1" paragraph>
              This tool allows you to query the Last Door CRM database using natural language. 
              You can ask questions about donors, donations, clients, and other data stored in the CRM.
            </Typography>
            <form onSubmit={handleSubmit} className="ask-crm-form">
              <textarea
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Enter your question here..."
                rows={4}
                className="query-input"
              />
              <button type="submit" disabled={loading} className="submit-button">
                {loading ? 'Processing...' : 'Submit'}
              </button>
            </form>
            {error && <div className="error-message">{error}</div>}
            {response && (
              <div className="response-container">
                <h3>Response:</h3>
                {renderResponse(response)}
                <Typography variant="h6" mt={2}>SQL Query:</Typography>
                <pre className="sql-query">{response.sqlQuery}</pre>
                {response.explanation && (
                  <>
                    <Typography variant="h6" mt={2}>Explanation:</Typography>
                    <Typography className="explanation">{response.explanation}</Typography>
                  </>
                )}
              </div>
            )}
          </Box>
        </Paper>
      </div>
    </>
  );
};

export default AskTheCRM;