// src/components/LandingPage.js
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import lastdoor from '../resources/lastdoor.png'; // Ensure correct path
import { Button, Typography, AppBar, Toolbar, Box, Container, Stack, Grid } from '@mui/material';
import { Science, Dashboard, Group, AccessTime, Description, SmartToy } from '@mui/icons-material';
import Header from './Header';

const LandingPage = () => {
  const navigate = useNavigate();
  const { auth, logout } = useContext(AuthContext);

  if (!auth.token) {
    navigate('/');
    return null;
  }

  const handleNavigation = path => {
    navigate(path);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Header />
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Grid container spacing={2} justifyContent="center">
          {[
            { title: 'Clinical Generator', icon: <Science />, path: '/clinical-generator' },
            { title: 'Dashboard Calls', icon: <Dashboard />, path: '/dashboard-calls' },
            { title: 'Dashboard Clients', icon: <Group />, path: '/dashboard-clients' },
            { title: 'Dashboard Waitlist', icon: <AccessTime />, path: '/dashboard-waitlist' },
            { title: 'Combined Dashboard', icon: <Dashboard />, path: '/dashboard-combined' },
            { title: 'MRP Interpretive Summary', icon: <Description />, path: '/mrp-interpretive-summary' },
            { title: 'Ask the CRM', icon: <SmartToy />, path: '/ask-the-crm' },
          ].map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Button
                variant="contained"
                fullWidth
                size="large"
                onClick={() => handleNavigation(item.path)}
                startIcon={item.icon}
                sx={{
                  height: 80,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  whiteSpace: 'normal',
                  lineHeight: 1.2,
                }}
              >
                {item.title}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default LandingPage;
