import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Button, CircularProgress, Box, Paper, TextField, Grid } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip, BarChart, Bar, XAxis, YAxis, CartesianGrid, LineChart, Line } from 'recharts';
import lastdoorLogo from '../resources/lastdoor.png';
import Header from '../components/Header';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

// Create an axios instance with the base URL of your backend server
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`, // Ensure this points to your backend server
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`, // Include auth headers if needed
  },
});

const DashboardWaitlist = () => {
  const [dateRange, setDateRange] = useState([
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    new Date().toISOString().split('T')[0],
  ]);
  const [waitlistData, setWaitlistData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [chartHeight, setChartHeight] = useState(300);

  useEffect(() => {
    const updateChartHeight = () => {
      const viewportHeight = window.innerHeight;
      const baseHeight = 300; // Current fixed height
      const minHeight = baseHeight * 0.8; // 80% of current size
      const maxHeight = baseHeight; // 100% of current size
      const calculatedHeight = Math.max(minHeight, Math.min(maxHeight, viewportHeight * 0.3));
      setChartHeight(calculatedHeight);
    };

    updateChartHeight();
    window.addEventListener('resize', updateChartHeight);
    return () => window.removeEventListener('resize', updateChartHeight);
  }, []);

  const fetchWaitlistData = async () => {
    setLoading(true);
    setError('');
    try {
      console.log('Fetching waitlist data with date range:', dateRange);
      const response = await api.get('/waitlist-data', {
        params: { startDate: dateRange[0], endDate: dateRange[1] }
      });
      console.log('Received response:', response.data);
      setWaitlistData(response.data);
    } catch (err) {
      setError('Failed to fetch waitlist data. Please try again.');
      console.error('Error fetching waitlist data:', err);
    }
    setLoading(false);
  };

  const renderMetrics = () => {
    if (!waitlistData) return null;

    const { acceptance_rate, avg_calls, returning_client_percentage } = waitlistData;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
            <Typography variant="h6">Acceptance Rate</Typography>
            <Typography variant="h4">
              {parseFloat(acceptance_rate[0].acceptance_rate).toFixed(1)}%
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
            <Typography variant="h6">Avg. Calls per Applicant</Typography>
            <Typography variant="h4">
              {parseFloat(avg_calls[0].average_calls).toFixed(1)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
            <Typography variant="h6">Returning Clients</Typography>
            <Typography variant="h4">
              {parseFloat(returning_client_percentage[0].returning_client_percentage).toFixed(1)}%
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  };

  const renderStatusDistribution = () => {
    if (!waitlistData || !waitlistData.status_distribution) return null;

    const data = waitlistData.status_distribution.map(item => ({
      name: item.Status,
      value: parseInt(item.Count)
    }));

    return (
      <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>Status Distribution</Typography>
        <ResponsiveContainer width="100%" height={chartHeight}>
          <PieChart>
            <Pie data={data} cx="50%" cy="50%" outerRadius={chartHeight * 0.4} fill="#8884d8" dataKey="value" label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}>
              {data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </Grid>
    );
  };

  const renderAverageAgeByStatus = () => {
    if (!waitlistData || !waitlistData.avg_age_by_status) return null;

    const data = waitlistData.avg_age_by_status.map(item => ({
      name: item.lastdoor_status_243,
      value: parseFloat(item.average_age)
    }));

    return (
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>Average Age by Status</Typography>
        <ResponsiveContainer width="100%" height={chartHeight}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    );
  };

  const renderFundingSuccessRate = () => {
    if (!waitlistData || !waitlistData.funding_success_rate) return null;

    const data = waitlistData.funding_success_rate.map(item => ({
      name: item.Result,
      value: parseInt(item.Count)
    }));

    return (
      <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>Funding Success Rate</Typography>
        <ResponsiveContainer width="100%" height={chartHeight}>
          <PieChart>
            <Pie data={data} cx="50%" cy="50%" outerRadius={chartHeight * 0.4} fill="#8884d8" dataKey="value" label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}>
              {data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </Grid>
    );
  };

  const renderNewWaitlistsByDate = () => {
    if (!waitlistData || !waitlistData.new_waitlists_by_date) return null;

    const data = waitlistData.new_waitlists_by_date.map(item => ({
      date: new Date(item.date).toLocaleDateString(),
      newEntries: item.new_entries
    }));

    return (
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>New Waitlist Entries Over Time</Typography>
        <ResponsiveContainer width="100%" height={chartHeight}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="newEntries" stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      </Grid>
    );
  };

  const renderWaitlistDurationDistribution = () => {
    if (!waitlistData || !waitlistData.waitlist_duration_distribution) return null;

    const data = waitlistData.waitlist_duration_distribution.map(item => ({
      name: item.duration_range,
      value: parseInt(item.count)
    }));

    return (
      <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>Waitlist Duration Distribution</Typography>
        <ResponsiveContainer width="100%" height={chartHeight}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    );
  };

  const renderTopReferralSources = () => {
    if (!waitlistData || !waitlistData.top_referral_sources) return null;

    const data = waitlistData.top_referral_sources.map(item => ({
      name: item.referral_source,
      value: parseInt(item.count)
    }));

    return (
      <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>Top Referral Sources</Typography>
        <ResponsiveContainer width="100%" height={chartHeight}>
          <PieChart>
            <Pie data={data} cx="50%" cy="50%" outerRadius={chartHeight * 0.4} fill="#8884d8" dataKey="value" label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}>
              {data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </Grid>
    );
  };

  return (
    <>
      <Header />
      <Paper elevation={3} sx={{ p: 3, m: 2 }}>
        <Box display="flex" flexDirection="column" alignItems="center">

          <Typography variant="h4" gutterBottom>
            Waitlist Dashboard v0.2
          </Typography>

          <Typography variant="body1" paragraph>
            This Waitlist Dashboard tool provides a comprehensive overview of waitlist data for Last Door
            Recovery Society. It allows staff to visualize and analyze key metrics for clients on the waitlist,
            including acceptance rates, status distributions, demographics, and funding information.
          </Typography>

          <Box display="flex" gap={2} mb={2}>
            <TextField
              label="Start Date"
              type="date"
              value={dateRange[0]}
              onChange={(e) => setDateRange([e.target.value, dateRange[1]])}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="End Date"
              type="date"
              value={dateRange[1]}
              onChange={(e) => setDateRange([dateRange[0], e.target.value])}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Button variant="contained" onClick={fetchWaitlistData} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Get Data'}
          </Button>

          {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}

          {waitlistData && (
            <Box mt={4} width="100%">
              {renderMetrics()}
              <Grid container spacing={4} sx={{ mt: 2 }}>
                {renderStatusDistribution()}
                {renderFundingSuccessRate()}
                {renderWaitlistDurationDistribution()} {/* Add this line */}
                {renderTopReferralSources()} {/* Add this line */}
                {renderAverageAgeByStatus()}
                {renderNewWaitlistsByDate()} {/* Add this line */}
              </Grid>
            </Box>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default DashboardWaitlist;